import axios from './api';

class loginApi {

  static Login = (login, password) => {

    const data = { "email": login, "password": password };

    return axios.post(`auth/users/login`, data);
  };

  static Logout = () => {

    return axios.post(`auth/users/logout`);
  };

  static Register = (login, password, password_confirmation) => {

    const data = { "email": login, "password": password, "password_confirmation": password_confirmation };

    return axios.post(`auth/users/register`, data);
  };

  static RegisterWithInvite = (inviteLink, password, password_confirmation) => {

    const data = { "inviteLink": inviteLink, "password": password, "password_confirmation": password_confirmation };

    return axios.post(`auth/users/invite-register`, data);
  };

  static ResetPassword = (resetLink, password, password_confirmation) => {

    const data = {  "new_password": password, "password_confirmation": password_confirmation };

    return axios.post(`auth/users/change-password/${resetLink}`, data);
  };

  static RemindPassword = (login) => {

    const data = { "email": login };

    return axios.post(`auth/users/request-password-reset`, data);
  };

  static GetGoogleLoginUrl = () => {
    return axios.get(`auth/users/google-login-url`);
  }

  static GoogleLogin = (code, state) => {
    const data = { "code": code, "state": state };
    return axios.post(`auth/users/google-login`, data);
  }

  static GetGoogleRegisterUrl = () => {
    return axios.get(`auth/users/google-register-url`);
  }

  static GoogleRegister = (code, state) => {
    const data = { "code": code, "state": state };
    return axios.post(`auth/users/google-register`, data);
  }
}
export default loginApi;