import { Form, Button } from "react-bootstrap";
import loginApi from "../api/loginApi";
import { useState } from "react";
import { isLoadingAtom } from "../atoms/atoms";
import { useSetAtom } from "jotai";
import { alertMessageAtom,showAlertAtom } from "../atoms/atoms";
import { IsValidEmail } from "../services/validationService";
import { useAuth } from "../hooks/useAuth";

const HrlLoginForm = ({t}) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const setIsLoading = useSetAtom(isLoadingAtom);
    const setAlertMessage = useSetAtom(alertMessageAtom);
    const setShowAlert = useSetAtom(showAlertAtom);
    const { login } = useAuth();

    const isValidationOk = () => {
        if (email.trim().length === 0) {
            setAlertMessage(t("validation.emailEmpty"));
            setShowAlert(true);
            return false;
        }

        if (!IsValidEmail(email)) {
            setAlertMessage(t("validation.emailInvalid"));
            setShowAlert(true);
            return false;
        }

        if (password.trim().length === 0) {
            setAlertMessage(t("validation.passwordEmpty"));
            setShowAlert(true);
            return false;
        }

        if (password.trim().length < 8) {
            setAlertMessage(t("validation.passwordTooShort"));
            setShowAlert(true);
            return false;
        }

        return true;
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onLogin();
        }
      }

    const onLogin = () =>{
        if (isValidationOk()) {
            setIsLoading(true);
            const logon = async () => {
                try {
                    let response = await loginApi.Login(email, password);
                    if (response.data && response.data.success === false) {
                        setAlertMessage(t(response.data.message));
                        setShowAlert(true);
                        setIsLoading(false);
                    } else {
                        login(response.data.user, true);
                    }
                    
                } catch (err) {
                    setAlertMessage(t("error.unknown"));
                    setShowAlert(true);
                    setIsLoading(false);
                }
            }

            logon();
        }
    }

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email:</Form.Label>
                <Form.Control 
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    maxLength="200"
                    type="email" 
                    placeholder={t("enter.email")} 
                    autoComplete="username"/>
            </Form.Group>


            <Form.Group className="" controlId="formBasicPassword">
                <Form.Label>Hasło:</Form.Label>
                <Form.Control 
                    value={password}
                    onChange={(e) => {setPassword(e.target.value)}}
                    onKeyDown={handleKeyDown}
                    maxLength="30"
                    type="password" 
                    placeholder={t("enter.password")}  
                    autoComplete="current-password" />
            </Form.Group>
            


            <Button onClick={onLogin} variant="primary" className="w-100 login-btn mt-3">
            {t("button.login")}
            </Button>
        </Form>
    )
}
export default HrlLoginForm;