import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CentralCard from "../components/CentralCard";
import AnimatedGroup from "../components/AnimatedGroup";
import TitleHrlytics from "../components/TitleHrlytics";
import { Alert, Card, Stack } from "react-bootstrap";
import inviteApi from "../api/inviteApi";
import HrlInviteHeader from "../components/HrlInviteHeader";
import { useSetAtom } from "jotai";
import { alertMessageAtom, showAlertAtom, isLoadingAtom } from "../atoms/atoms";
import HrlInviteRegistration from "../components/HrlInviteRegistration";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InvitationPage = () => {
    const params = useParams();
    const [invite, setInvite] = useState(null);
    const [notFound, setNotfound] = useState(false);

    const setIsLoading = useSetAtom(isLoadingAtom);
    const setAlertMessage = useSetAtom(alertMessageAtom);
    const setShowAlert = useSetAtom(showAlertAtom);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        setIsLoading(true);
        document.title = t("titles.invite");

        if (params.inviteLink) {
            const getInvite = async () => {
                try {
                    let response = await inviteApi.GetInviteByLink(params.inviteLink);
            
                    if (response.data) {

                        const invite = response.data;
                        if (invite.inviteStatus === 2) {
                            //redirect to login
                            navigate('/login?inviteUsed=true');
                        } else if (invite.inviteStatus === 1) {
                            setInvite(response.data);
                        } else {
                            setNotfound(true);
                        }

                    } else {
                        setAlertMessage(t("error.unknown"));
                        setShowAlert(true);
                    }

                } catch (err) {
                    if (err.response.status === 404) {
                        setNotfound(true);
                    } else {
                        setAlertMessage(t("error.unknown"));
                        setShowAlert(true);
                    }
                    
                } finally {
                    setIsLoading(false);
                }
            }

            getInvite();
        } else {
            setAlertMessage(t("error.unknown"));
            setShowAlert(true);
            setIsLoading(false);
        }

    }, []);

    if (invite === null) {
        return (
            <CentralCard>

                <AnimatedGroup>
                    <TitleHrlytics />
                    {notFound &&
                        <Alert variant="danger">
                            <div className="info-parent">
                                <span>{t("text.invitationNotFound")}</span>
                                <span>{t("text.checkLink")}</span>
                            </div>
                        </Alert>}
                </AnimatedGroup>
            </CentralCard>
        );
    }
    else {
        return (
            <CentralCard>

                <AnimatedGroup>
                    <TitleHrlytics />
                    <Card>
                        <Card.Body>
                            <Stack className="mb-3 mt-3" gap={3}>
                                <HrlInviteHeader invite={invite} t={t} />
                                <HrlInviteRegistration inviteLink={params.inviteLink} t={t} />
                            </Stack>
                        </Card.Body>
                    </Card>
                </AnimatedGroup>
            </CentralCard>
        );
    }
}
export default InvitationPage;