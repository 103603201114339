import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { isLoadingAtom } from "../atoms/atoms";
import { useSetAtom } from "jotai";
import { alertMessageAtom,showAlertAtom } from "../atoms/atoms";
import loginApi from "../api/loginApi";
import CentralCard from "../components/CentralCard";
import AnimatedGroup from "../components/AnimatedGroup";
import TitleHrlytics from "../components/TitleHrlytics";
import SignInIncentive from "../components/SignInIncentive";
import RegisterIncentive from "../components/RegisterIncentive";
import { useTranslation } from "react-i18next";

const GoogleRegister = () => {

    const { t } = useTranslation();
    const setIsLoading = useSetAtom(isLoadingAtom);
    const setAlertMessage = useSetAtom(alertMessageAtom);
    const setShowAlert = useSetAtom(showAlertAtom);
    const [queryParams, setQueryparam] = useSearchParams();

    const code = queryParams.get('code');
    const state = queryParams.get('state');

    useEffect(()=>{
        setIsLoading(true);
        document.title = t("titles.register")

        if(code){
            const login = async () => {
                try {
                    let response = await loginApi.GoogleRegister(code, state);
                    if (response.data) {
                        if (response.data.success) {
                            window.location.href = process.env.REACT_APP_DEFAULT_REDIRECT;
                        } else {
                            setAlertMessage(t(response.data.message));
                            setShowAlert(true);
                            setIsLoading(false);
                        }
                    
                    } else {
                        setAlertMessage(t("error.googleLogin"));
                        setShowAlert(true);
                        setIsLoading(false);
                    }
                    
                } catch (err) {
                    console.log(err);
                    setAlertMessage(t("error.unknown"));
                    setShowAlert(true);
                    setIsLoading(false);
                }
            }
    
            login();
        } else {
            setAlertMessage(t("error.unknown"));
                    setShowAlert(true);
                    setIsLoading(false);
        }

    },[]);

return(
    <CentralCard>

            <AnimatedGroup>
                <TitleHrlytics />
                <div className="text-center w-100 my-4" style={{ display: "flex" }}>
                    <SignInIncentive t={t} />
                </div>
                <div className="text-center w-100 my-4" style={{ display: "flex" }}>
                    <RegisterIncentive t={t} />
                </div>
            </AnimatedGroup>
        </CentralCard>
)
}

export default GoogleRegister;