import { Form, Button } from "react-bootstrap";
import { useRef, useState } from "react";
import { showAlertAtom, alertMessageAtom } from "../atoms/atoms";
import { useSetAtom } from "jotai";
import { IsValidEmail, IsValidPassword } from "../services/validationService";
import { hasPasswordFieldFocusAtom } from "../atoms/atoms";
import PasswordStrengthViewOffCnavas from "./PasswordStrengthViewOffCnavas";
import { isLoadingAtom } from "../atoms/atoms";
import loginApi from "../api/loginApi";
import GenericInfo from "./GenericInfo";
import { useNavigate  } from "react-router-dom";

const HrlRegisterForm = ({t}) => {


    const [password, setPassword] = useState("");
    const [retypedPassword, setRetypedPassword] = useState("");
    const [email, setEmail] = useState("");
    const setShowAlert = useSetAtom(showAlertAtom);
    const setAlertMessage = useSetAtom(alertMessageAtom);
    const setIsFocused = useSetAtom(hasPasswordFieldFocusAtom);
    const hasFocus = useRef(false);
    const setIsLoading = useSetAtom(isLoadingAtom);
    const navigate = useNavigate();

    const onFocus = () => {
        if (!hasFocus.current) {
            hasFocus.current = true;
            setIsFocused(true);
        }
    }

    const onBlur = () => {
        if (hasFocus.current) {
            hasFocus.current = false;
            setIsFocused(false);
        }
    }

    const isValidationOk = () => {
        if (email.trim().length === 0 || password.trim().length === 0 || retypedPassword.trim().length === 0) {
            setAlertMessage(t("validation.allFieldsRequired"));
            setShowAlert(true);
            return false;
        }

        if (!IsValidEmail(email)) {
            setAlertMessage(t("validation.emailInvalid"));
            setShowAlert(true);
            return false;
        }

        if (!IsValidPassword(password)) {
            setAlertMessage(t("validation.passwordWeak"));
            setShowAlert(true);
            return false;
        }

        if (password.trim() !== retypedPassword.trim()) {
            setAlertMessage(t("validation.passwordsDontMatch"));
            setShowAlert(true);
            return false;
        }
        return true;
    }


    const onRegister = () => {
        if (isValidationOk()) {
            setIsLoading(true);
            const register = async () => {
                try {
                    let response = await loginApi.Register(email, password, retypedPassword);
                    if (response.data && response.data.success === false) {
                        setAlertMessage(t(response.data.message));
                        setShowAlert(true);
                       
                    } else {
                        navigate('/login?registered=true');
                    }

                } catch (err) {
                    setAlertMessage(t("error.unknown"));
                    setShowAlert(true);
                    
                } finally {
                    setIsLoading(false);
                }
            }

            register();
        }
    }
  
        return (
            <Form>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>{t('text.email')}</Form.Label>
                    <Form.Control
                        maxLength="200"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        type="email"
                        placeholder={t('enter.email')}
                        autoComplete="username" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Hasło:</Form.Label>
                    <Form.Control
                        maxLength="30"
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={password}
                        type="password"
                        placeholder={t('enter.newPassword')}
                        autoComplete="new-password" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="retypedPassword">
                    <Form.Label>Powtórz hasło:</Form.Label>
                    <Form.Control
                        maxLength="30"
                        onChange={(e) => setRetypedPassword(e.target.value)}
                        value={retypedPassword}
                        type="password"
                        placeholder={t('enter.repeatPassword')}
                        autoComplete="new-password" />
                </Form.Group>

                <PasswordStrengthViewOffCnavas t={t} password={password} retypedPassword={retypedPassword} />

                <Button onClick={onRegister} variant="primary" className="w-100 login-btn mt-1">
                    {t('button.createAccount')}
                </Button>
            </Form>
        )
    
}
export default HrlRegisterForm;