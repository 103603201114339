import { Stack, Card } from "react-bootstrap";
import OrBar from "../components/OrBar";
import GoogleLoginButton from "../components/GoogleLoginButton";
import TitleHrlytics from "../components/TitleHrlytics";
import HrlLoginForm from "../components/HrlLoginForm";
import RegisterIncentive from "../components/RegisterIncentive";
import CentralCard from "../components/CentralCard";
import AnimatedGroup from "../components/AnimatedGroup";
import { useEffect } from "react";
import InfoBlock from "../components/InfoBlock";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LoginPage() {

    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("titles.login");
    }, [])

    return (
        <CentralCard>

            <AnimatedGroup>
                <TitleHrlytics />
                <InfoBlock t={t} />
                <Card>
               
                        <Stack className="mb-3 mt-3" gap={3}>
                            <HrlLoginForm t={t} />
                            <OrBar t={t}/>
                            <GoogleLoginButton t={t} />
                        </Stack>
              
                </Card>
                <div className="mt-1 mb-3 text-center">
                    <Link className="register-btn" to="/forgot-password">
                        {t("text.forgotPassword")}
                    </Link>
                </div>
                {/* <div className="text-center w-100 my-4" style={{ display: "flex" }}>
                    <RegisterIncentive t={t} />
                </div> */}
            </AnimatedGroup>
        </CentralCard>
    );
}