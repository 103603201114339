import React from 'react';
import { isLoadingAtom } from '../atoms/atoms';
import { useAtom } from 'jotai';
import LoadingOverlay from './LoadingOverlay';

const LoadingScreen = () => {

    const [isLoading] = useAtom(isLoadingAtom);

    if (isLoading) {
        return (
            <LoadingOverlay />
        );
    }
};

export default LoadingScreen;
