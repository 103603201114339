import { useOutlet } from "react-router-dom";
import GenericAlertModal from "./GenericAlertModal";
import { useEffect } from "react";
import appVersionService from "../services/appVersionService";
import { useAuth } from "../hooks/useAuth";

const Layout = () => {

  const outlet = useOutlet();
  const { setPanelAppVersion } = useAuth();

  useEffect(() => {
      getPanelVersion();
  }, []);

  const getPanelVersion = async () => {
    const appVersion = await appVersionService.getPanelVersion();
    setPanelAppVersion(appVersion);
  }

  return (
    <div id="layout">
      <GenericAlertModal />
      {outlet}
    </div>
  );
};

export default Layout;