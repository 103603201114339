import AnimatedGroup from "../components/AnimatedGroup"
import CentralCard from "../components/CentralCard"
import SignInIncentive from "../components/SignInIncentive"
import TitleHrlytics from "../components/TitleHrlytics"
import { useTranslation } from "react-i18next";

export const NotFound = () => {
    const { t } = useTranslation();

    return (
        <CentralCard>

        <AnimatedGroup>
            <TitleHrlytics />
            <div className="text-center w-100 mt-4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h1>{t("error.header")}</h1>
                    <h1>{t("error.404")}</h1>
            </div>
            <div className="text-center w-100 my-5" style={{ display: "flex" }}>
                    <SignInIncentive t={t} />
            </div>
        </AnimatedGroup>
    </CentralCard>
    )
}