import { motion } from "framer-motion";

const AnimatedGroup = ({children}) => {

return (
    <motion.div
            initial={{
                opacity: 0,
                //y: '-10vw', // startuje poza ekranem po lewej stronie
            }}
            animate={{
                opacity: 1,
               // y: 0, // kończy na swoim pierwotnym miejscu
                transition: { duration: 0.2 }
            }}
            exit={{
                opacity: 0,
               // y: '10vw', // kończy poza ekranem po prawej stronie
                transition: { duration: 0.2 }
            }}

        >
            {children}
        </motion.div>
);

}


export default AnimatedGroup;