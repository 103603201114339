import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import App from './App';
import reportWebVitals from './reportWebVitals';
import LoadingScreen from "./components/LoadingScreen";
import './i18n';

const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <LoadingScreen />
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
