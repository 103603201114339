import { Button, Form } from "react-bootstrap";
import PasswordStrengthViewOffCnavas from "./PasswordStrengthViewOffCnavas";
import { useRef, useState } from "react";
import { useSetAtom } from "jotai";
import { hasPasswordFieldFocusAtom, showAlertAtom, alertMessageAtom, isLoadingAtom } from "../atoms/atoms";
import { IsValidPassword } from "../services/validationService";
import loginApi from "../api/loginApi";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const HrlInviteRegistration = ({inviteLink, t}) => {   
    const [password, setPassword] = useState("");
    const [retypedPassword, setRetypedPassword] = useState("");
    const hasFocus = useRef(false);
    const setIsFocused = useSetAtom(hasPasswordFieldFocusAtom);
    const setShowAlert = useSetAtom(showAlertAtom);
    const setAlertMessage = useSetAtom(alertMessageAtom);
    const setIsLoading = useSetAtom(isLoadingAtom);
    const navigate = useNavigate();
    const { login } = useAuth();

    const onFocus = () => {
        if (!hasFocus.current) {
            hasFocus.current = true;
            setIsFocused(true);
        }
    }

    const onBlur = () => {
        if (hasFocus.current) {
            hasFocus.current = false;
            setIsFocused(false);
        }
    }

    const isValidationOk = () => {
        if (password.trim().length === 0 || retypedPassword.trim().length === 0) {
            setAlertMessage(t("validation.allFieldsRequired"));
            setShowAlert(true);
            return false;
        }

        if (!IsValidPassword(password)) {
            setAlertMessage(t("validation.passwordWeak"));
            setShowAlert(true);
            return false;
        }

        if (password.trim() !== retypedPassword.trim()) {
            setAlertMessage(t("validation.passwordsDontMatch"));
            setShowAlert(true);
            return false;
        }
        return true;
    }

    const onRegister = () => {
        if (isValidationOk()) {
            setIsLoading(true);
            const register = async () => {
                try {
                    let response = await loginApi.RegisterWithInvite(inviteLink, password, retypedPassword);
                    if (response.data && response.data.success === false) {
                        setAlertMessage(t(response.data.message));
                        setShowAlert(true);
                        setIsLoading(false);
                    } else {
                        login(response.data.user, true);
                    }

                } catch (err) {
                    setAlertMessage(t("error.unknown"));
                    setShowAlert(true);
                    setIsLoading(false);  
                } 
            }

            register();
        }
    }

    return (
        <Form>
                          
           
                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>{t("text.password")}:</Form.Label>
                    <Form.Control
                        maxLength="30"
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={password}
                        type="password"
                        placeholder={t("enter.newPassword")}
                        autoComplete="new-password" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="retypedPassword">
                    <Form.Label>{t("text.repeatPassword")}:</Form.Label>
                    <Form.Control
                        maxLength="30"
                        onChange={(e) => setRetypedPassword(e.target.value)}
                        value={retypedPassword}
                        type="password"
                        placeholder={t("enter.repeatPassword")}
                        autoComplete="new-password" />
                </Form.Group>

                <PasswordStrengthViewOffCnavas t={t} password={password} retypedPassword={retypedPassword} />

                <Button onClick={onRegister} variant="primary" className="w-100 login-btn mt-1">
                    {t("button.join")}
                </Button>
            </Form>
    )

}
export default HrlInviteRegistration;