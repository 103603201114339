import React from 'react';
import '../css/LoadingOverlay.css';
import { isLoadingAtom } from '../atoms/atoms';
import { useSetAtom } from 'jotai';

const LoadingOverlay = () => {

    const setisLoading = useSetAtom(isLoadingAtom);

    return (
        <div onClick={() => setisLoading(false)} className={`loading-overlay-container `}>
            <div className={`loading-overlay text-center loading`}>
                <div className="loading-spinner">
                    <div className="spinner-border preloader" role="status">
                        <span className="visually-hidden">loading</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingOverlay;
