import { useAtom } from "jotai";
import { showAlertAtom, alertMessageAtom } from "../atoms/atoms";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";

const GenericAlertModal = () => {

    const [message,] = useAtom(alertMessageAtom);
    const [show, setShow] = useAtom(showAlertAtom);

    useEffect(()=>{
        const img = new Image();
        img.src = process.env.REACT_APP_BASENAME + "/assets/info_icon_inv.svg";
    },[])

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="pb-5">
                <div className="text-center mb-3">
                <img src={process.env.REACT_APP_BASENAME + "/assets/info_icon_inv.svg"} width="40" alt="warning" style={{width: "40px"}} />
                    {/* <span className="material-symbols-outlined" style={{fontSize: "50px"}}>
                        error
                    </span> */}
                </div>
                <div className="text-center">
                {message}
                </div>
            </Modal.Body>

        </Modal>
    )

}

export default GenericAlertModal;