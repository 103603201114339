import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CentralCard from "../components/CentralCard";
import AnimatedGroup from "../components/AnimatedGroup";
import TitleHrlytics from "../components/TitleHrlytics";
import { Card, Stack } from "react-bootstrap";
import HrlResetPassword from "../components/HrlResetPassword";
import SignInIncentive from "../components/SignInIncentive";
import { useTranslation } from "react-i18next";

const ResetPasswordPage = () => {

    const { t } = useTranslation();
    const params = useParams();

    useEffect(() => {
        document.title = t("titles.forgotPassword");
    },[]);

    return (
        <CentralCard>

        <AnimatedGroup>
            <TitleHrlytics />
            <Card>
                <Card.Body>
                    <Stack className="mb-3 mt-3" gap={3}>
                      
                        <HrlResetPassword t={t} resetLink={params.resetLink} />
                    </Stack>
                </Card.Body>
            </Card>
            <div className="text-center w-100 my-4" style={{ display: "flex" }}>
                    <SignInIncentive t={t} />
                </div>
        </AnimatedGroup>
    </CentralCard>
    )
}
export default ResetPasswordPage;