import { Button, Form } from "react-bootstrap";
import GenericInfo from "./GenericInfo";
import loginApi from "../api/loginApi";
import { alertMessageAtom, isLoadingAtom, showAlertAtom } from "../atoms/atoms";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { IsValidEmail } from "../services/validationService";
import { useNavigate } from "react-router-dom";

const HRLRemindPassword = ({t}) => {

    const setIsLoading = useSetAtom(isLoadingAtom);
    const setShowAlert = useSetAtom(showAlertAtom);
    const setAlertMessage = useSetAtom(alertMessageAtom);
    const [email, setEmail] = useState("");
    const navigate = useNavigate()


    const isValidationOk = () => {
        if (email.trim().length === 0) {
            setAlertMessage(t("validation.emailEmpty"));
            setShowAlert(true);
            return false;
        }

        if (!IsValidEmail(email)) {
            setAlertMessage(t("validation.emailInvalid"));
            setShowAlert(true);
            return false;
        }

        return true;
    }

    const onClick = () => {
       if (isValidationOk()) {
            setIsLoading(true);
            const resetPassword = async () => {
                try {
                    let response = await loginApi.RemindPassword(email);
                    if (response.data && response.data.success === false) {
                        setAlertMessage(t(response.data.message));
                        setShowAlert(true);
                       
                    } else {
                        navigate('/login?remind=true');
                    }

                } catch (err) {
                    setAlertMessage(t("error.unknown"));
                    setShowAlert(true);
                    
                } finally {
                    setIsLoading(false);
                }
            }

            resetPassword();
      }
    }

    return (
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email:</Form.Label>
                <Form.Control 
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    maxLength="200"
                    type="email" 
                    placeholder={t("enter.email")} 
                    autoComplete="username" />
            </Form.Group>

            <Button onClick={onClick} variant="primary" className="w-100 login-btn mt-1 mb-3">
                {t("button.forgotPassword")}
            </Button>
            
            <GenericInfo>
                <span>{t("text.recoveryInfo")}</span>
            </GenericInfo>
        </Form>
    );
}

export default HRLRemindPassword;