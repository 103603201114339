import { useEffect, useState } from "react";
import unsubscribeApi from "../api/unsubscribeApi";
import { useParams } from "react-router-dom";
import { alertMessageAtom, isLoadingAtom, showAlertAtom } from "../atoms/atoms";
import { useSetAtom } from "jotai";
import CentralCard from "../components/CentralCard";
import AnimatedGroup from "../components/AnimatedGroup";
import TitleHrlytics from "../components/TitleHrlytics";
import { Alert, Button, Card, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const UnsubscribePage = () => {

    const params = useParams();
    const setIsLoading = useSetAtom(isLoadingAtom);
    const setAlertMessage = useSetAtom(alertMessageAtom);
    const setShowAlert = useSetAtom(showAlertAtom);

    const [hasUnsubscribed, setHasUnsubscibed] = useState(false);
    const [hashData, setHashData] = useState(null);

    const { t } = useTranslation();

    useEffect(()=>{
        document.title = t("titles.unsubscribe");
        loadEmailByHash();
    },[params.hash])

    const loadEmailByHash = async () => {
        setIsLoading(true);
        try {
            let response = await unsubscribeApi.GetEmailByHash(params.hash);

            if (response.data) {
                if ( response.data.success && response.data.data) {
                    if (response.data.data.has_unsubscibed === 1) {
                        setHasUnsubscibed(true);
                    } else {
                        setHashData(response.data.data);
                    }
                } else {
                    setAlertMessage(t("error.noInvite"));
                    setShowAlert(true);
                }
            } else {
                setAlertMessage(t("error.unknown"));
                setShowAlert(true);
            }
        } catch (err) {
            setAlertMessage(t("error.unknown"))
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    }

    const unsubscribe = async () => {
        setIsLoading(true);
        try {
            let response = await unsubscribeApi.Unsubscribe(params.hash);

            if (response.data && response.data.success) {
                setHasUnsubscibed(true);
            } else {
                setAlertMessage(t("error.unknown"));
                setShowAlert(true);
            }
        } catch (err) {
            setAlertMessage(t("error.unknown"));
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <CentralCard>

        <AnimatedGroup>
            <TitleHrlytics />
            <Card>
                <Card.Body>
                    <Stack className="mb-3 mt-3" gap={3}>
                        {hashData && !hasUnsubscribed && 
                            <div className="text-center">
                                <p>{t("text.confirmUnsubscribe")}</p>
                                <Button onClick={unsubscribe} variant="primary" className="w-100 login-btn mt-3">
                                    {t("button.unsubscribe")}
                                </Button>
                            </div>
                        }
                        {hasUnsubscribed && 
                            <Alert variant="success" className="text-center">
                                <p>{t("text.thankYou")}</p> <p>{t("text.unsubscibeConfirmation")}</p>
                            </Alert>
                        }
                        
                    </Stack>
                </Card.Body>
            </Card>
        </AnimatedGroup>
    </CentralCard>
    );
}
export default UnsubscribePage;