import { useEffect, useRef } from 'react';
import '../css/HrlInviteHeader.css';

const HrlInviteHeader = ({invite, t}) => { 

    const divOrgaRef = useRef(null);
    const divEmailRef = useRef(null);

    useEffect(() => {
        const divElement = divOrgaRef.current;
        const divEmailElement = divEmailRef.current;

        const elementWidth = divElement.offsetWidth;
        const textLength = divElement.textContent.length;

        const emailElementWidth = divEmailElement.offsetWidth;
        const emailTextLength = divEmailElement.textContent.length;
        
        let targetFontSize = Math.floor(elementWidth / textLength);
        let targetEmailFontSize = Math.floor(emailElementWidth*1.5 / emailTextLength);
    
        targetFontSize > 20 ? targetFontSize = 20 : targetFontSize = targetFontSize;
        targetFontSize < 11 ? targetFontSize = 10 : targetFontSize = targetFontSize;

        targetEmailFontSize > 20 ? targetEmailFontSize = 20 : targetEmailFontSize = targetEmailFontSize;
        targetEmailFontSize < 11 ? targetEmailFontSize = 10 : targetEmailFontSize = targetEmailFontSize;

        divElement.style.fontSize = `${targetFontSize}px`;
        divEmailElement.style.fontSize = `${targetEmailFontSize}px`;
        
    }, [invite]);

    return(
        <div className="invite-header">
            <h2>{t("text.inviteFor")}</h2>
            <div className='invite-email mb-2' ref={divEmailRef} >{invite.invitedEmail}</div>
            <div style={{fontSize: "0.8rem", marginTop: "1rem"}}>{t("text.setPasswordToCreateAccount")}</div>
            <div className='invite-orga' ref={divOrgaRef}>{invite.accountName}</div>
        </div>
    );
}
export default HrlInviteHeader;