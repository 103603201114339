import '../css/Footer.css';
import DarkModeSwitch from './DarkModeSwitch';
import { useTranslation } from "react-i18next";

const Footer = () => {

    const { t } = useTranslation();

    return (
        <div className='my-4'>
            <DarkModeSwitch />
            <div className="row">
                <div className="col-lg-12">
                    <div className="text-center footer-items">
                        <a href='https://greatdigital.pl/kontakt/' rel="noreferrer" target='_blank'>
                            {t("footer.contact")}
                        </a>&nbsp;|&nbsp;
                        <a href={process.env.REACT_APP_PRIVACY_POLICY_URL} rel="noreferrer" target='_blank'>
                            {t("footer.privacy")}
                        </a>&nbsp;|&nbsp;
                        <a href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL} rel="noreferrer" target='_blank'>
                            {t("footer.terms")}
                        </a>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Footer;