import axios from './api';

class unsubscribeApi {
    static GetEmailByHash = (hash) => {
        return axios.get(`hrlytics/unsubscribe/${hash}`);
      };

    static Unsubscribe = (hash) => {
        return axios.patch(`hrlytics/unsubscribe/${hash}`);
    };
}
export default unsubscribeApi;