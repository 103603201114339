import { useEffect } from 'react';
import '../css/GenericInfo.css';

const GenericInfo = ({children}) => {

    useEffect(()=>{
        const img = new Image();
        img.src = process.env.REACT_APP_BASENAME + "/assets/info_icon_inv.svg";
    },[])

    return (
        <div className="generic-info">
            <div className='generic-info-icon'>
                {/* <span className="material-symbols-outlined">info</span> */}
                <img src={process.env.REACT_APP_BASENAME + "/assets/info_icon_inv.svg"} width="30" alt="warning" style={{width: "30px"}} />
            </div>
            <div className='generic-info-text'>
                {children}
            </div>
        </div>
    );
}

export default GenericInfo;