import { Card, Stack } from "react-bootstrap";
import CentralCard from "../components/CentralCard";
import TitleHrlytics from "../components/TitleHrlytics";
import HRLRemindPassword from "../components/HRLRemindPassword";
import SignInIncentive from "../components/SignInIncentive";
import RegisterIncentive from "../components/RegisterIncentive";
import AnimatedGroup from "../components/AnimatedGroup";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ForgotPasswordPage = () => {

    const { t } = useTranslation();

    useEffect(()=>{
        document.title = t("titles.forgotPassword");
    },[])

    return (
        <CentralCard>

            <AnimatedGroup>
                <TitleHrlytics />
                <Card>
                    <Card.Body>
                        <Stack className="mb-3 mt-3" gap={3}>
                            <HRLRemindPassword t={t} />

                        </Stack>
                    </Card.Body>
                </Card>
                <div className="text-center w-100 my-4" style={{ display: "flex" }}>
                    <SignInIncentive t={t} />
                </div>
                {/* <div className="text-center w-100 my-4" style={{ display: "flex" }}>
                    <RegisterIncentive t={t} />
                </div> */}
            </AnimatedGroup>
        </CentralCard>
    );
}

export default ForgotPasswordPage;