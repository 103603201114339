import { useEffect } from "react"
import AnimatedGroup from "../components/AnimatedGroup"
import CentralCard from "../components/CentralCard"
import SignInAgainIncentive from "../components/SignInAgainIncentive"
import TitleHrlytics from "../components/TitleHrlytics"
import { isLoadingAtom } from "../atoms/atoms";
import { useSetAtom } from "jotai";
import loginApi from "../api/loginApi";
import { useAuth } from "../hooks/useAuth";
import { alertMessageAtom,showAlertAtom } from "../atoms/atoms";
import { useTranslation } from "react-i18next";

const LogoutPage = () => {

    const setIsLoading = useSetAtom(isLoadingAtom);
    const setAlertMessage = useSetAtom(alertMessageAtom);
    const setShowAlert = useSetAtom(showAlertAtom);
    const { logout } = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t("titles.logout");

        setIsLoading(true);
        const callLogout = async () => {
            try {
                let response = await loginApi.Logout();
                logout();

            } catch (err) {
                setAlertMessage(t("error.unknown"));
                setShowAlert(true);
            } finally {
                setIsLoading(false);
            }
        }

        callLogout();
    }, []);

    return (
        <CentralCard>

            <AnimatedGroup>
                <TitleHrlytics />
                <div className="text-center w-100 mt-4" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h2>{t("text.loggedOffLine1")}</h2>
                    <h2>{t("text.loggedOffLine2")}</h2>
                </div>
                <div className="text-center w-100 my-5" style={{ display: "flex" }}>
                    <SignInAgainIncentive t={t}/>
                </div>
            </AnimatedGroup>
        </CentralCard>
    );
}
export default LogoutPage;