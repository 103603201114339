import { Alert } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

const InfoBlock = ({t}) => {
    const [queryParams, setQueryparam] = useSearchParams();

    const sessionExpired = queryParams.get('sessionExpired');
    const activated = queryParams.get('activated');
    const registered = queryParams.get('registered');
    const reset = queryParams.get('reset');
    const remind = queryParams.get('remind');
    const inviteUsed = queryParams.get('inviteUsed');


    const messageText = () => {
        if (sessionExpired) {
            return [t("messages.sessionExpired.line1"),t("messages.sessionExpired.line2")];
        } else if (activated) {
            return [t("messages.activated.line1"),t("messages.activated.line2")];
        } else if (registered) {
            return [t("messages.registered.line1"),t("messages.registered.line2")];
        } else if (reset) {
            return [t("messages.reset.line1"),t("messages.reset.line2")];
        } else if (remind) {
            return [t("messages.remind.line1")];
        } else if (inviteUsed) {
            return [t("messages.inviteUsed.line1"),t("messages.inviteUsed.line2")];
        } else {
            return null;
        }
    }

    const formattedMessage = (lines) => {
        return (
            <div className="info-parent">
                {lines.map((line, index) => {
                    return <span key={index}>{line}</span>
                })}
            </div>
        );
    }

 
    if (messageText()) {
        return (
            <Alert variant="success">
                {formattedMessage(messageText())}
            </Alert>
        )
    }

}

export default InfoBlock;