import { createContext, useContext, useMemo, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [panelAppVersion, setPanelAppVersion] = useState("0");

  const login = async (userData, redirect=true) => {
    setUser(userData);
    if (redirect) { redirectAfterLogin(userData.roleId); }
  };

  const redirectAfterLogin = (roleId) => {

    const params = new URLSearchParams(window.location.search);
    const url = process.env.REACT_APP_INSIGHT_REDIRECT + `?v=${panelAppVersion}`;
    window.location.href = params.has('location') ? params.get('location') : url;
  };

  const logout = () => {
    setUser(null);
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setPanelAppVersion
    }),
    [user, panelAppVersion]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};