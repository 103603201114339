import { Card, Stack } from "react-bootstrap"
import TitleHrlytics from "../components/TitleHrlytics"
import OrBar from "../components/OrBar"
import SignInIncentive from "../components/SignInIncentive"
import HrlRegisterForm from "../components/HrlRegisterForm"
import GoogleRegisterButton from "../components/GoogleRegisterButton"
import CentralCard from "../components/CentralCard"
import AnimatedGroup from "../components/AnimatedGroup"
import { useEffect } from "react"
import { useTranslation } from "react-i18next";

const RegisterPage = () => {

    const { t } = useTranslation();

    useEffect(()=>{
        document.title = t("titles.register");
    },[])

    return (

        <CentralCard>

            <AnimatedGroup>
                <TitleHrlytics />
                <Card>
                    <Card.Body>
                        <Stack className="mb-3 mt-3" gap={3}>
                            <HrlRegisterForm t={t} />
                            <OrBar t={t} />
                            <GoogleRegisterButton t={t} />
                        </Stack>
                    </Card.Body>
                </Card>
                <div className="text-center w-100 my-4" style={{ display: "flex" }}>
                    <SignInIncentive t={t} />
                </div>
            </AnimatedGroup>
        </CentralCard>


    )
}
export default RegisterPage;