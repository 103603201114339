import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { defaultLanguage } from "./services/CommonServices";

i18next
    .use(initReactI18next)
    .use(Backend)
    .init({
        supportedLngs: ['en_gb', 'pl_pl', 'fr_fr', 'de_de', 'it_it', 'es_es'],
        lng: defaultLanguage(),
        load: 'currentOnly',
        fallbackLng: "en_gb",
        debug: process.env.REACT_APP_DEBUG === "true",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: `${process.env.REACT_APP_BASENAME}/locales/{{lng}}/{{ns}}.json`,
          },
    });