import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
const RegisterIncentive = ({t}) => {
    return (
        <Stack direction="horizontal" className="mx-auto">
            <div className="my-1 me-1">{t("text.noAccount")}</div>
            <div className="my-1">
                <Link  className="register-btn" to="/register">
                    {t("button.register")}
                </Link>
            </div>
        </Stack>
    );
}
export default RegisterIncentive;