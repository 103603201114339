import appVersionApi from "../api/appVersionApi";

class appVersionService {

    static getPanelVersion = async () => {
   
        try {
            let response = await appVersionApi.GetAppVersion("panel.ui");
    
            if (response.data && response.data.appVersion) {
    
                return response.data.appVersion;
    
            } else {
                return "0";
            }
    
        } catch (error) {
            return "0";
        } 
    }


}
export default appVersionService;