import { Offcanvas, Stack } from "react-bootstrap"
import { hasPasswordFieldFocusAtom } from "../atoms/atoms";
import { useAtom } from "jotai";
import '../css/PasswordStrengthViewOffCnavas.css';

const PasswordStrengthViewOffCnavas = ({ password, t }) => {


    const [show, setShow] = useAtom(hasPasswordFieldFocusAtom);

    const hasUpperCase = () => {
        return password.match(new RegExp("[A-Z]"));
    }

    const hasLowerCase = () => {
        return password.match(new RegExp("[a-z]"))
    }

    const hasDigit = () => {
        return password.match(new RegExp("[0-9]"));
    }

    const hasLength = () => {
        return password.trim().length >= 8;
    }

    const missingCharsText = () => {
        if (password.length < 8) {
            if (8 - password.length > 1) return numberText(8 - password.length);
        }
        return t("passStrength.missing1");
    }

    const numberText = (nr) => {
        return t(`passStrength.missing${nr}`);
    }


    return (<>
        <Offcanvas show={show} onHide={() => setShow(false)} scroll={true} backdrop={false} autoFocus={false} restoreFocus={false} placement="top">
            <Offcanvas.Header closeButton>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Stack className="mb-5 px-1">
                    <div className="w-100 text-center password-rule-head">
                    {t("passStrength.title")}
                    </div>
                    <div className="password-rule-wrapper">
                        <div className={hasUpperCase() ? "password-rule rule-valid" : "password-rule rule-invalid"} >
                            {hasUpperCase() && <><span className="material-icons">done</span>{t("passStrength.capitalOK")}</>}
                            {!hasUpperCase() && <><span className="material-icons">highlight_off</span>{t("passStrength.capitalKO")}</>}
                        </div>
                        <div className={hasLowerCase() ? "password-rule rule-valid" : "password-rule rule-invalid"} >
                            {hasLowerCase() && <><span className="material-icons">done</span>{t("passStrength.letterOK")}</>}
                            {!hasLowerCase() && <><span className="material-icons">highlight_off</span>{t("passStrength.letterKO")}</>}
                        </div>
                        <div className={hasDigit() ? "password-rule rule-valid" : "password-rule rule-invalid"} >
                            {hasDigit() && <><span className="material-icons">done</span>{t("passStrength.numberOK")}</>}
                            {!hasDigit() && <><span className="material-icons">highlight_off</span>{t("passStrength.numberKO")}</>}
                        </div>
                        <div className={hasLength() ? "password-rule rule-valid" : "password-rule rule-invalid"} >
                            {hasLength() && <><span className="material-icons">done</span>{t("passStrength.length1OK")} {password.length} {t("passStrength.characters")}</>}
                            {!hasLength() && <><span className="material-icons">highlight_off</span>{t("passStrength.missing")}  {missingCharsText()}</>}

                        </div>
                    </div>
                </Stack>
            </Offcanvas.Body>
        </Offcanvas>
    </>
    )
}

export default PasswordStrengthViewOffCnavas;