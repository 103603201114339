import { useLocalStorage } from "./useLocalStorage"
import { useAtom } from "jotai";
import { languageAtom } from "../atoms/atoms";
import { useTranslation } from 'react-i18next';

export const useLocalization = () => {
    const [, setLang] = useLocalStorage("hrlLang", null);
    const [language, setLanguageState] = useAtom(languageAtom);
    const { i18n } = useTranslation();
   

    const getLanguageName = (lang) => {
        switch (lang) {
            case "pl_pl": return "Polski";
            case "en_us": return "English";
            case "en_gb": return "English";
            case "de_de": return "Deutsch";
            case "it_it": return "Italiano";
            case "es_es": return "Español";
            case "fr_fr": return "Français";

            default: return "Polski";
        }
    };

    const hasSelectedLanguage = () => {
        return language !== null;
    }

    const setLanguage = (newLang) => {
        setLanguageState(newLang);
        setLang(newLang);
        i18n.changeLanguage(newLang);
    }

    return { language, getLanguageName, hasSelectedLanguage, setLanguage };
}

