import Footer from "./Footer";
import LanguageBlock from "./LanguageBlock";

const CentralCard = ({ children }) => {

    return (
            <div className="min-vh-100 d-flex flex-row align-items-center ">
                <div className="container-fluid">
                    <div className="row  justify-content-center">
                        <div className="col-lg-8" style={{ maxWidth: "450px" }}>
                            <LanguageBlock />
                            {children}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
    );
}

export default CentralCard;