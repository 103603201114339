import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
const SignInIncentive = ({t}) => {
    return (
        <Stack direction="horizontal" className="mx-auto">
            <div className="my-1 me-1">
                {t("text.alreadyHaveAccount")}
            </div>
            <div className="my-1">
                <Link  className="register-btn" to="/login">
                    {t("button.goToLogin")}
                </Link>
            </div>
        </Stack>
    );
}
export default SignInIncentive;