import './../css/LanguageSwitcher.css';
import { useLocalization } from '../hooks/useLocalization';
import { Dropdown } from 'react-bootstrap';

const LanguageSwitcher = ({ ...props }) => {

    const localization = useLocalization();

    const languages = ['en_gb', 'de_de', 'es_es', 'fr_fr', 'it_it', 'pl_pl'];

    const changeLanguage = (lng) => {
        localization.setLanguage(lng);
    };

    return (
   
            <Dropdown>
                <Dropdown.Toggle id="languageSwitch"   {...props}>
                  
                    <img className='languageSwitch-flag' src={process.env.REACT_APP_BASENAME + "/flags/" + localization.language + ".svg"} alt={localization.getLanguageName(localization.language)} />
                    
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {languages.map((lang) => (
                        <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)} eventKey="lang">
                            <img className='languageSwitch-flag me-2' src={process.env.REACT_APP_BASENAME + "/flags/" + lang + ".svg"} alt={localization.getLanguageName(lang)} />
                            {localization.getLanguageName(lang)}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
      
    );
}
export default LanguageSwitcher;